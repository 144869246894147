<script>
import MenuItemService from '@/service/menu-item.service';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'modal-new-menu-item',

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: {},
    };
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    create() {
      this.clearForm();
      this.dialog = true;
    },

    clearForm() {
      this.form = {};
    },

    edit(id) {
      this.fetch(id);
      this.dialog = true;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const menuItem = await MenuItemService.get(id);
        console.log(menuItem);
        this.form = cloneDeep(menuItem);
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await MenuItemService.update(id, form);
        } else {
          await MenuItemService.create(form);
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    handleCancel() {
      this.dialog = false;
      this.clearForm();
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="525px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de Item do cardápio</span>
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-text-field
            v-model="form.name"
            label="Nome"
            color="white"
            hide-details="auto"
            outlined
            persistent-placeholder
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="form.calorific_value"
            label="Valor calórico (por porção de 100g)"
            color="white"
            hide-details="auto"
            outlined
            persistent-placeholder
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="form.protein"
            label="Proteínas (por porção de 100g)"
            color="white"
            hide-details="auto"
            outlined
            persistent-placeholder
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="form.group"
            :items="[
              { text: 'Mais perecíveis', value: 'MORE_PERISHABLE' },
              { text: 'Menos perecíveis', value: 'LESS_PERISHABLE' },
            ]"
            label="Grupo"
            color="white"
            hide-details="auto"
            outlined
            persistent-placeholder
            dense
          />
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCancel"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.v-tabs-items {
  background-color: transparent !important;
}

.container-tab {
  min-height: 600px;
}

.registration-container {
  width: 100%;
  height: 100%;

  .registration-box {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px dashed rgba(255, 255, 255, 0.15);
  }
}
</style>
